console.log("SERVICE-WORKER","installed!");



function getWindowClients() {
  return clients.matchAll({
    type: 'window',             // we only want windows/tabs
    includeUncontrolled: true,  // we only want those associated with this worker
  });
}

function isClientFocused(windowClients) {
  for (let i = 0; i < windowClients.length; i++) {
      const windowClient = windowClients[i];
      if (windowClient.focused) {
        return true;
      }
  }
  return false;
}


    
// handle incoming push-notifications:
// a) post a message if a focused client-window exists
// b) otherwise show notification
self.addEventListener('push', function(event) {

    let pushdata = event.data.json();
    console.log("SERVICE-WORKER","push event",pushdata);

    const promiseChain = getWindowClients().then((windowClients) => {
      
      // if we have a focused client-window/tab, we just send a message
      // to that tab. no need for a desktop-notification
      if (isClientFocused(windowClients)) {
        console.log("SERVICE-WORKER","sending push event to focused client");
        windowClients.forEach((windowClient) => {
          windowClient.postMessage({
            message: 'webpush received',
            data: pushdata,
            time: new Date().toString(),
          });
        });
        return;
      } 

      // not focused: show notification:
      console.log("SERVICE-WORKER","no focused client, showing notification");
      return self.registration.showNotification(
        pushdata.title, {
          "body": pushdata.body,
          "data": pushdata,
          "actions": [
            {
              "action": "click",
              "title": "Öffnen"
            }
          ]
      });


    });
    
    event.waitUntil(promiseChain);
});

console.log("SERVICE-WORKER","push event wired");

// handle click on notification: 
// a) bring existing window to front
// b) otherwise: open window
self.addEventListener('notificationclick', function(event) {

  let pushdata = event.notification.data;

  console.log("SERVICE-WORKER","notification-click",event);

  const clickedNotification = event.notification;
  clickedNotification.close();

  const urlToOpen = new URL("/", self.location.origin).href;
  let specificURLToOpen = urlToOpen;
  if ((pushdata.data != null) && (pushdata.data.navigate_to != null)) {
    specificURLToOpen = urlToOpen + pushdata.data.navigate_to;
  }

  const promiseChain = getWindowClients().then((windowClients) => {

    let matchingClient = null;
  
    for (let i = 0; i < windowClients.length; i++) {
      const windowClient = windowClients[i];
      if (windowClient.url.indexOf(specificURLToOpen) == 0) {
        matchingClient = windowClient;
        break;
      }
    }

    if (matchingClient == null) {
      for (let i = 0; i < windowClients.length; i++) {
        const windowClient = windowClients[i];
        if (windowClient.url.indexOf(urlToOpen) == 0) {
          matchingClient = windowClient;
          break;
        }
      }
    }

    if (matchingClient) {
      console.log("SERVICE-WORKER","focusing matching client");
      matchingClient.postMessage({
        message: 'webpush notification clicked',
        data: pushdata,
        time: new Date().toString(),
      });
      return matchingClient.focus();
    } else {
      console.log("SERVICE-WORKER","no matching client, opening new window");
      return clients.openWindow(urlToOpen);
    }

  });
  event.waitUntil(promiseChain);
});



  console.log("SERVICE-WORKER","click event wired");